<template>
  <div>
    <container>

      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>

      <div slot="bottom_box_content">
        <component :is="curBottomComponent"></component>
      </div>

      <div slot="bg_map">
        <component :is="curMapComponent"></component>
      </div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import comAssetStoreModule from '@/store/modules/comAsset'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/List.vue'),
    Center: () => import('./components/Center'),
    Right: () => import('./components/Right')
  },
  data () {
    return {
    }
  },
  created () {
    if (!this.$store.hasModule('comAsset')) {
      this.$store.registerModule('comAsset', comAssetStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(true)
    this.setShowMap(true)
    this.setBottomHeight('300px')

    this.setMapComponent('Center')
    this.setRightComponent('Right')
    this.setBottomComponent('Bottom')
  },
  destroyed () {
    // 重置state，并注销moudle
    this.$store.commit('reset_state')
    this.$store.unregisterModule('comAsset')
  }
}
</script>
