import { getDeviceModelQuantity, getStatusQuantity } from '@/api/rim/device'
const getDefaultState = () => {
  return {
    assetArray: [],
    curAssetId: null,
    statusArray: [],
    deviceModelStaData: [],
    deviceStatusStaData: [],
    stationArray: [],
    curDeviceInfo: {},
    bigModelArray: []
  }
}

const state = getDefaultState()

const comAsset = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_asset_array (state, data) {
      state.assetArray = data
    },
    set_cur_assetId (state, data) {
      state.curAssetId = data
    },
    set_status_array (state, data) {
      state.statusArray = data
    },
    set_device_model_sta_data (state, data) {
      state.deviceModelStaData = data
    },
    set_deivce_status_sta_data (state, data) {
      state.deviceStatusStaData = data
    },
    set_station_array (state, data) {
      state.stationArray = data
    },
    set_cur_device_info (state, data) {
      state.curDeviceInfo = data
    },
    set_big_model_array (state, data) {
      state.bigModelArray = data
    }
  },
  actions: {
    getDeviceModelStaData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getDeviceModelQuantity(query).then(res => {
          if (res && !res.errcode) {
            commit('set_device_model_sta_data', res)
          } else {
            commit('set_device_model_sta_data', [])
          }
          resolve(res)
        })
      })
    },
    getDeviceStatusStaData ({ state, commit }, query) {
      return new Promise((resolve) => {
        getStatusQuantity(query).then(res => {
          if (res && !res.errcode) {
            commit('set_deivce_status_sta_data', res)
          } else {
            commit('set_deivce_status_sta_data', [])
          }

          resolve(res)
        })
      })
    }
  }
}

export default comAsset
